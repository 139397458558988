html {
  --section-background-color: linear-gradient(to bottom left, #fcfafc, #fcfafc);

  --imp-text-color: #236aff;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  background-image: url(./Assets/sup/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #001412;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #236aff;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #236aff;
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  opacity: 0.95;
  transition: all 0.3s ease-out 0s !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  padding: 0.25rem 0rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #236aff !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #00000000 !important;
    opacity: 0.95;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 10em !important;
  width: 12em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 0rem !important;
  padding-left: 0.8rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
    background-color: #236aff;
  }
  .logo {
    height: 4em !important;
    width: 4.5em !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-size: 1.4rem;
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: '';
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #236aff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.home-header {
  padding-top: 80px !important;
}

@media (max-width: 980px) {
  .home-header {
    padding-top: 1px !important;
  }
}

.home-section {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 10px !important;
}

.home-content {
  max-width: 90% !important;
  padding: 8rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  background-image: url(./Assets/main/bg.png);
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #236aff;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #236aff !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #236aff !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-title {
  color: #236aff;
  font-size: 50px;
  font-weight: 900;
}

.home-text {
  color: white;
  font-size: 75px;
  font-weight: 900;
  padding-top: 3.4rem;
}
.home-text2 {
  color: white;
  font-size: 20px;
}
.home-text3 {
  color: white;
  font-size: 25px;
}

@media (max-width: 980px) {
  .home-text {
    font-size: 35px;
    padding-top: 1.3rem;
  }
  .home-text2 {
    font-size: 14px;
  }
  .home-text3 {
    font-size: 12px;
    padding-top: 1.3rem;
  }
}

.home-about-description {
  color: white !important;
  text-align: center;
}

.home-about-body {
  padding-top: 10px;
  font-size: 1.1em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 55px !important;
  height: 55px !important;
  text-align: center !important;
  font-size: 0.9em !important;
  line-height: 1.2em !important;
  background: #236aff !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #46266c;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px white;
}

.home-social-icons:hover {
  color: white;
  box-shadow: 0 0 5px white;
  text-shadow: 0 0 2px white;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.social-icons:hover {
  color: white;
}

.icon-colour {
  color: #46266c !important;
}

@media (max-width: 767px) {
  .home-social-icons {
    width: 33px !important;
    height: 33px !important;
    text-align: center !important;
    font-size: 1.9em !important;
    line-height: 1.3em !important;
  }
  .home-about-social-links {
    display: flex !important;
  }
  .social-icons {
    display: inline-block !important;
    padding: 5px;
  }
}

/* --------- */
/* nft */
/* --------- */
.nft-section {
  z-index: +1000;
  position: relative;
}

.nft-text {
  font-size: 70px;
  font-weight: 900;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
}

.nft-text2 {
  font-size: 60px;
  font-weight: 900;
  color: #fff;
  padding-left: 20rem;
}

.nft-content {
  max-width: 70% !important;
  padding: 8rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.nft-hr {
  width: 6px;
  height: 55px;
  background: #236aff;
}

@media (max-width: 980px) {
  .nft-text {
    font-size: 40px;
  }
  .nft-text2 {
    font-size: 20px;
    padding-left: 0;
  }
  .nft-content {
    max-width: 70% !important;
    padding: 4rem 0 2rem !important;
  }

  .nft-hr {
    width: 6px;
    height: 30px;
    background: #236aff;
  }
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #001412;
  opacity: 0.7;
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer h1 {
  font-size: 0.7em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #236aff !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px #008a7e !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px #236aff !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #00c5b4 !important;
  border-color: #00c5b4 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #00ecd8 !important;
  border-color: #00ecd8 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #236aff !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 2px 2px 2px 2px #002724;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 3px solid #236aff !important;
  color: #236aff;
  box-shadow: 2px 2px 2px 2px #002724 !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.project-heading {
  color: #ffffff !important;
  font-size: 4.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

.find-us-on-text {
  color: #236aff !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff,
    -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* roadmap */
/* --------- */
.roadmap-content {
  max-width: 70% !important;
  padding: 8rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.roadmap-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  color: #236aff !important;
}

.roadmap {
  padding-top: 10px;
  padding-bottom: 50px;
}

.roadmap-left {
  padding-right: 80px !important;
}

.roadmap-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .roadmap-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .roadmap-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.roadmap .roadmap-title {
  font-size: 2.2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.roadmap .roadmap-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #236aff;
  position: relative;
}

.roadmap .roadmap-item .roadmap-title {
  line-height: 16px;
  font-size: 1.3em;
  color: black;
  background: #236aff;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 1px;
}

.roadmap .roadmap-item ul {
  padding-left: 20px;
  text-align: justify;
}

.roadmap .roadmap-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.roadmap .roadmap-item:last-child {
  padding-bottom: 0;
}

.roadmap .roadmap-item::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #236aff;
}

@media (max-width: 767px) {
  .logoPN {
    height: 6em !important;
    width: 6em !important;
  }
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 35%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.find-us-on {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.find-us-on a {
  display: flex;
  align-items: center;
  text-align: center;
  flex: 1;
  min-height: 100px;
  border-style: double;
  border-color: #236aff;
}

.find-us-on img {
  margin: 0 auto;
  min-height: 50px;
}

.logoPN {
  width: 60%;
}

.logoRotate {
  width: 20rem;
  animation: spinHorizontal 4s infinite linear;
}

@keyframes spinHorizontal {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.ca {
  font-size: 2.7rem;
  width: 100%;
  height: 80px;
  background: rgba(44, 29, 81, 0.5);
  border: 2px solid #236aff;
}
@media (max-width: 800px) {
  .ca {
    width: 100%;
    height: 50px;
    font-size: 0.7rem;
    padding: 0;
  }
  .ca span {
    padding-top: 12px;
  }
}
